<template>
  <form
    action
    data-vv-scope="studio-selection-form"
    @submit.prevent="validateForm('studio-selection-form')"
  >
    <v-radio-group
      v-model="customer.studio"
      data-vv-validate-on="blur"
      v-validate="'required'"
      :error-messages="errors.collect('studio-selection-form.studio')"
      data-vv-name="studio"
    >
      <v-row>
        <v-col
          cols="6"
          sm="6"
          md="3"
          v-for="(studio, i) in tattooer.studios"
          :key="i"
        >
          <v-card
            @click="
              selectStudio(studio);
              studio.selected = true;
            "
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'width: 93%; height: 20em'
                : 'width: 100%; height: 20em'
            "
            class="my-2"
            v-bind:class="[
              {
                select: customer.studio ? studio.id === customer.studio.id : '',
              },
            ]"
          >
            <v-row
              style="text-align: center; max-width: 100% !important"
              class="mr-0 ml-0"
            >
              <v-radio
                style="position: absolute; left: 5px; top: 5px"
                :value="studio"
              ></v-radio>
              <v-avatar
                color="primary"
                size="100"
                style="
                  margin: 0 auto;
                  font-size: 50px;
                  text-transform: uppercase;
                  color: #272727;
                  cursor: pointer;
                  margin-top: 50px;
                "
                >{{ nameAvatar(studio.studio_name) }}</v-avatar
              >
              <v-list-item-title
                class="pt-7 px-1"
                style="
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                  font-size: 16px;
                  cursor: pointer;
                "
              >
                {{ studio.studio_name }} </v-list-item-title
              ><br />
              <v-list-item-subtitle style="color: #999" class="pt-3 px-1">
                Estancia: Del
                {{
                  $d(new Date(studio.tattooer_studios[0].from), "date2digits")
                }}
                <span v-if="studio.tattooer_studios[0].to"
                  >al
                  {{
                    $d(new Date(studio.tattooer_studios[0].to), "date2digits")
                  }}</span
                >
              </v-list-item-subtitle>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-radio-group>
    <div class="pa-3 text-right">
      <v-btn
        color="primary"
        @click="$emit('back')"
        style="height: 30px"
        outlined
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        style="height: 30px"
        elevation="0"
        class="ml-2"
      >
        {{ $t("next") }}
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "StudioSelect",
  methods: {
    nameAvatar(name) {
      //console.log("AVATAR");
      //console.log ("AVATAR", name);
      let array = name.split("/");

      let arr = array[array.length - 1];
      //console.log(arr);
      arr = arr.split(" ");
      //console.log(arr);
      let nom = "";

      arr.forEach((word, i) => {
        if (nom.length < 3 && word.length > 0) nom += word[0];
      });
      // //console.log(nom);
      return nom;
    },
    selectStudio(studio) {
      this.$set(this.customer, "studio", studio);
      this.$store.commit("inscriptions/SET_CUSTOMER", this.customer);
      this.$emit("validated", this.studio);
    },
    async validateForm(scope) {
      this.errors.clear();

      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.$emit("validated", this.studio);
        }
      });
    },
  },
  computed: {
    ...mapState("inscriptions", ["tattooerState", "customerState"]),
    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
  },
};
</script>



<style lang="sass" scoped>
.select
  border: 1px solid var(--v-primary-base) !important
</style>